<template>
  <v-container class="py-0 " style="height: 100%" >
    <v-row style="height: 106%">
      <v-stepper v-model="currentStep" class="mt-0 bg-glass-effect" style="height: 100%" >
        <v-stepper-content step="1" class="pa-0" style="height: 100%">
          <v-col cols="12" class="pt-0">
            <v-row>
              <v-col cols="12">
                <BackArrow class="mt-5" route="historicoTriagem"/>
              </v-col>
            </v-row>
            <v-card elevation="8" class="mx-0 py-0 item-card" color="#FADD8F">
              <v-card-text class="mt-16 py-2 px-1 alert-covid">
                <v-icon left color="white" class="pl-">fas fa-exclamation-triangle</v-icon>Possibilidade de Positivo para COVID-19
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
          <v-row class="">
            <v-col cols="12" class="pt-1">
              <p style="margin-bottom: 0" class="generic-title">
                O que fazer?
              </p>
            </v-col>

            <v-col cols="12" class="pb-0">
              <p class="text-center mb-0" v-html="covidConfiguration.possibleCovidTemplate">
              </p>
            </v-col>

            <v-col cols="12" class="pb-0">
              <p class="generic-title mb-0">
                Já consultou?
              </p>

              <v-checkbox @change="declaracaoNaoLiberado = false; declaracaoLiberado = true" v-model="declaracaoLiberado">
                <template v-slot:label>
                  <div>
                    <span class="checkbox-description">Declaro que fui liberado por um médico</span>
                  </div>
                </template>
                </v-checkbox>
              <v-checkbox @change="declaracaoLiberado = false; declaracaoNaoLiberado = true" v-model="declaracaoNaoLiberado" class="mt-0">
                <template v-slot:label>
                  <div>
                    <span class="checkbox-description">Declaro que fui ao médico, mas não fui liberado</span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-flex xs12 class="mr-4 pt-0">
              <case-file-upload
                ref="customFileUpload"
                :maximum="1"
                :limit-file-size="12"
                @update="setAttachments"
                label="Atestado médico"
              />
            </v-flex>
            <v-col class="py-0" cols="12">
              <GenericBtn
                class="login-btn-steps-mode"
                :btn-props="{
                  block: true,
                  color: 'primary',
                  large: true,
                }"
                :on-click="avancarStepNomeMedico"
              >Avançar
              </GenericBtn>

            </v-col>
          </v-row>
        </v-col>
        </v-stepper-content>
        <v-stepper-content step="2" fluid style="height: 100vh" class="px-2">
          <v-row>
            <v-col cols="12">
              <v-btn icon color="#EA4965" @click="currentStep = covidGuidanceSteps.atestado">
                <img style="width: 28px; height: 23px" src="/img/icons/common/back-arrow.svg"/>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">

              <p class="mb-0" v-if="medicalRelease.attachments && medicalRelease.attachments.length > 0" style="color: #868698">
                Atestado anexado
              </p>
            </v-col>

            <v-col cols="12">
            <p class="generic-title" style="font-size: 32px !important;">
              Informe o nome do médico
            </p>
            </v-col>
          </v-row>

          <v-flex class="email-resize" style="margin-top: 10px;">
            <v-text-field
              autofocus
              clearable
              clear-icon="fas fa-times grey--text"
              name="login"
              id="login"
              placeholder="Digite o nome"
              type="text"
              v-model="medicalRelease.doctorName"
              hide-details
            />
          </v-flex>

          <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '75%' }" align-end>
            <v-row class="mt-15 pt-15">
              <v-col align-self="end" cols="12">
                <GenericBtn
                  :btn-props="{
                    block: true,
                    color:'primary',
                    large: true,
                    style:'margin-bottom: 10px; border-radius: 12px;',
                    class: 'login-btn-steps-mode'
                  }"
                  :on-click="avancarStepCrmMedico"
                >
                  Avançar
                </GenericBtn>
              </v-col>
            </v-row>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="3" fluid style="height: 100vh" class="pt-0 px-2">
          <v-row>
            <v-col cols="12">
              <v-btn icon color="#EA4965" @click="currentStep = covidGuidanceSteps.nomeMedico">
                <img class="mt-6" style="width: 28px; height: 23px" src="/img/icons/common/back-arrow.svg"/>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="12" class="pt-0">

              <p class="mb-0" v-if="medicalRelease.attachments && medicalRelease.attachments.length > 0" style="color: #868698">
                Atestado anexado
              </p>
            </v-col>

            <v-col cols="12">
              <p class="generic-title" style="font-size: 32px !important;">
                Agora informe o CRM
              </p>
            </v-col>
          </v-row>
          <v-flex class="email-resize" style="margin-top: 10px;">

            <v-row>
              <v-col cols="8">
                <v-text-field
                  autofocus
                  clearable
                  clear-icon="fas fa-times grey--text"
                  name="login"
                  id="login"
                  placeholder="Digite o CRM"
                  type="text"
                  v-model="medicalRelease.doctorCrm"
                  hide-details
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  class="mb-2"
                  label="UF"
                  :rules="[...required]"
                  :items="UF"
                  single-line
                  item-text="uf"
                  item-value="uf"
                  v-model="medicalRelease.doctorUfCrm"
                />
              </v-col>
            </v-row>
          </v-flex>

          <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '75%' }" align-end>
            <v-row align="end" class="mt-15 pt-15">
              <v-col cols="12">
                <GenericBtn
                  :btn-props="{block: true,
                  color:'primary',
                  large: true,
                  style:'margin-bottom: 10px; border-radius: 12px;',
                  class:'login-btn-steps-mode' }"
                  :on-click='finalizar'
                >
                  Salvar
                </GenericBtn>
              </v-col>
            </v-row>
          </v-layout>
        </v-stepper-content>
      </v-stepper>

    </v-row>
  </v-container>
</template>

<script>
import store from "../../store/store";
import EuProtegidoService from "../../services/euprotegido/EuProtegidoService";
import CaseFileUpload from "../generic-upload/CaseFileUpload";
import GenericBtn from "../generic-btn/index"
import BackArrow from "../covid/back-arrow/index"
import CountryService from "../../services/security/CountryService";

export default {
  name: "covidGuidance",
  data: () => ({
    screeningId: "",
    medicalRelease:{},
    covidConfiguration: {},
    declaracaoLiberado:false,
    declaracaoNaoLiberado:false,
    currentStep: 1,
    covidGuidanceSteps: {
      atestado: 1,
      nomeMedico: 2,
      crmMedico: 3
    }
  }),
  components:{
    CaseFileUpload,
    GenericBtn,
    BackArrow
  },
  beforeMount () {
    this._euProtegidoService = new EuProtegidoService();
    this._countryService = new CountryService();
    this.loadRouteParams();
    this.loadCovidConfiguration();
    this.getAllCountry();
  },
  methods: {
    loadRouteParams(){
      this.screeningId = this.$route.params.screeningId;
    },
    getAllCountry(){
      this._countryService.getAllCountry().then((response) => {
        if(response.data){
          this.UF = response.data
        }
      })
    },
    loadCovidConfiguration(){
      this._euProtegidoService.GetCovidConfigurations()
        .then((response) => {
          this.covidConfiguration = response.data;
        })
        .catch(error => {
          store.commit('showmsg', {
            text: "Erro ao carregar Orientações",
            type: "error"
          });
        })

    },
    isAttachmentMandatory(){
      let mandatory = false;

      if(this.declaracaoLiberado)
        mandatory = this.covidConfiguration.covidOptions.includes("Obrigatório atestado para negativado")
      else if(this.declaracaoNaoLiberado)
        mandatory = this.covidConfiguration.covidOptions.includes("Obrigatório atestado para positivado")

      return mandatory;
    },
    isDoctorMandatory(){
      let mandatory = false;

      if(this.declaracaoLiberado)
        mandatory = this.covidConfiguration.covidOptions.includes("Obrigatório CRM para negativado")
      else if(this.declaracaoNaoLiberado)
        mandatory = this.covidConfiguration.covidOptions.includes("Obrigatório CRM para positivado")

      return mandatory;
    },
    setAttachments(attachments) {
        this.medicalRelease.attachments = attachments;
    },
    avancarStepNomeMedico(){
      window.scrollTo(0, 0)
      if(!this.declaracaoLiberado && !this.declaracaoNaoLiberado){
        store.commit('showmsg', {
          text: "Selecione uma das opções",
          type: "error",
        });
        return;
      }

      if((!this.medicalRelease.attachments || this.medicalRelease.attachments.length == 0) && !this.declaracaoNaoLiberado ){
        store.commit('showmsg', {
          text: "Atestado médico é obrigatório",
          type: "error"
        });
        return;
      }

      this.currentStep = this.covidGuidanceSteps.nomeMedico;
    },
    avancarStepCrmMedico(){
      window.scrollTo(0, 0)
      if(this.isDoctorMandatory() && !this.medicalRelease.doctorName){
        store.commit('showmsg', {
          text: "Nome do médico é obrigatório",
          type: "error"
        });
        return;
      }

      this.currentStep = this.covidGuidanceSteps.crmMedico;
    },
    finalizar(){
      if(this.isDoctorMandatory() && (!this.medicalRelease.doctorCrm || !this.medicalRelease.doctorUfCrm)){
        store.commit('showmsg', {
          text: "CRM/UF do médico são obrigatórios",
          type: "error"
        });
        return;
      }

      this.medicalRelease.screeningId = this.screeningId;

      if(this.medicalRelease.attachments){
          let file = this.medicalRelease.attachments[0];
          this.medicalRelease.medicalCertificateFileName =  file && file.name.split('.')[0];
          this.medicalRelease.medicalCertificateContentType = `.${file && file.name.split('.')[1]}`;
          this.medicalRelease.medicalCertificate = file && file.body.split(',')[1];
      }
      this.medicalRelease.medicalRelease = this.declaracaoLiberado
      this.medicalRelease.release = this.declaracaoLiberado
      this._euProtegidoService.SaveMedicalRelease(this.medicalRelease)
          .then((response) => {
            this.$router.push({ name: 'historicoTriagem' })
          })
          .catch(error => {
            store.commit('showmsg', {
              text: "Erro ao salvar Atestado",
              type: "error"
            });
          })
      },
  }
}
</script>

<style scoped>

  .bg-glass-effect {
    background: rgba(255, 255, 255, .6) !important;
    mix-blend-mode: normal !important;
    backdrop-filter: blur(100px) !important;
    height: 100%;
  }

  .alert-covid {
    color: #4A499B;
  }

  .checkbox-description {
    color: #292867;
  }
</style>
